<template>
  <div class="top">
    <div class="flex flex-row justify-between p-1 laptop:pb-2 desktop:pb-8">
      <tabs :tabs="tabsList" @updateIndex="updateIndex" :currentIndex="upIndex"></tabs>
    </div>
    <!-- Pending Approval -->

    <div style="padding:20px 0px; background-color: white; border-radius: 10px;">

      <div  class="flex flex-row justify-end pr-2">
        <div class="flex gap-x-4 text-right" style="width: 400px">
          <el-input class="Comipt" size="small" :placeholder="$t('btn.search')" v-model="searchValue"
            @keyup.enter.native="Pendinginputval(Pendinginput)">
            <span slot="suffix" @click="Pendinginputval(Pendinginput)">
              <svg-icon iconClass="search" />
            </span>
          </el-input>
          <el-select size="small" class="Pendingselect" v-model="selectValue" :placeholder="$t('btn.Today')">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="box-card" v-if="upIndex == 0">

        <div class="w-full">
          <div class="ReqTable w-full">
            <el-table stripe class="Comtable" @row-click="handleRowClick" :data="listData" :header-cell-style="{
              height: '55px',
              background: '#fff',
              'border-bottom': '2px solid #D6D9E1',
            }" :row-style="{
  height: '64px',
}">
              <el-table-column width="100" align="left" prop="createName" label="Requestor" />
              <el-table-column width="180" align="left" prop="createTime" label="Date and Time" />
              <el-table-column width="180" align="left" prop="reason" label="Reason" />
              <el-table-column align="right" fixed="right">
                <template slot-scope="scope">
                  <el-button :ref="'popoverRef_' + scope.row.id" size="small" plain v-if="userInfo.role == 1"
                    class="acknowledgedButton" @click="Acknowclick(scope.row)">
                    <span class="acknowledgedButton">
                      {{ $t("btn.Acknowledged") }}
                    </span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div class="box-card" v-if="upIndex == 1">
        <el-row type="flex" justify="end" class="Topinp">
          <el-col :span="9">
            <div class="Topinput">
              <el-input class="Comipt" size="small" :placeholder="$t('btn.search')" v-model="searchValue"
                @keyup.enter.native="Pendinginputval(Pendinginput)">
                <span slot="suffix" @click="Pendinginputval(Pendinginput)">
                  <svg-icon iconClass="search" />
                </span>
              </el-input>
              <el-select size="small" class="Pendingselect" v-model="selectValue" :placeholder="$t('btn.Today')">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="w-full">
            <div class="ReqTable w-full">
              <el-table :cell-style="getCellStyle" class="Comtable" :data="listData" :header-cell-style="{
                height: '55px',
                background: '#fff',
                'border-bottom': '2px solid #D6D9E1',
              }" :row-style="{
  height: '64px',
}" @row-click="handleRowClick">
                <el-table-column width="100" align="left" prop="createName" label="Requestor" />
                <el-table-column width="180" align="left" prop="createTime" label="Date and Time" />
                <el-table-column width="180" align="left" prop="reason" label="Reason" />
                <el-table-column align="right">
                  <template slot-scope="scope">
                    <el-button :ref="'popoverRef_' + scope.row.id" size="small" plain v-if="ReserBtn"
                      @click="ResersBtn(scope.row)">
                      <span class="resetButton">
                        {{ $t("btn.ReserSignature") }}
                      </span>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="flex justify-center flex-col items-center">
        <div class="w-full text-right">
          <pagination :total="total" :page.sync="pageParams.pageNum" :limit.sync="pageParams.pageSize"
            @pagination="getlist"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabs from "@/components/tabs.vue";
import { getrequestsList, AcknowData, getResers } from "@/services/user";
import { mapState } from "vuex";
import { getRequestNum } from "@/services";

export default {
  name: "requests",
  components: { tabs },

  data() {
    return {
      tabsList: [
        { label: "PendingApproval", count: 0, index: "0" },
        { label: "Acknowledge", count: 0, index: "1" },
      ],
      upIndex: 0,
      listData: [],
      listDatas: [],
      searchValue: "",
      selectValue: "",
      options: [],
      ReserBtn: false,
      AcknowBtn: true,
      // Acknowclick 接口修改
      Acknowdata: {},
      total: 0,
      pageParams: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    pendingList() {
      return this.listData.filter((item) => item.applyType == 0);
    },
    acknowledgedList() {
      return this.listData.filter((item) => item.applyType == 1);
    },
    ...mapState(["userInfo"]),
  },
  created() {
    this.getlist();
    this.getRequestNum();
  },
  activated() {
    this.getlist();
    this.getRequestNum();
  },
  methods: {
    // 获取列表
    getlist() {
      getrequestsList(this.upIndex, this.pageParams.pageNum, this.pageParams.pageSize).then((res) => {
        if (res.code == 200) {
          this.listData = res.rows;
          this.total = res.total;
        }
      });
      if (this.userInfo.role == 3) {
        // this.upIndex = 1;
        this.ReserBtn = true;
        this.AcknowBtn = false;
      }
    },
    updateIndex(index) {
      this.upIndex = index;
      this.getlist();
      this.getRequestNum();
    },
    // Pendinginput框
    Pendinginputval(value) {
      this.Pendinginput = "";
    },
    // Acknowledged 点击
    Acknowclick(val) {
      this.Acknowdata = val;
      if (this.userInfo.role == 1) {
        this.Acknowdata.applyType = 1;
        AcknowData({
          applyType: this.Acknowdata.applyType,
          id: val.id,
        }).then((res) => {
          if (res.code == 200) {
            this.getlist();
            this.getRequestNum();
          }
        });
      }
    },

    // ResersBtn点击
    ResersBtn(val) {
      getResers(val.id).then((res) => {
        this.$message.success(this.$t("status.success"));
        this.getlist();
        this.getRequestNum();
      });
    },
    // Acknowledged
    Acknowinputval(value) {
      this.Pendinginput = "";
    },
    getCellStyle({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return {
          backgroundColor: "rgba(255,255,255,0.2)",
        };
      } else {
        return {
          backgroundColor: "rgba(228,228,228,0.2)",
        };
      }
    },
    handleRowClick(row, col, event) {
      let target = event.target;
      if (
        target.classList.contains("resetButton") ||
        target.classList.contains("acknowledgedButton") ||
        target.classList.contains("el-button") ||
        target.classList.contains("acknowledgedButton")
      ) {
        return;
      }
      event.stopPropagation();
      this.$router.push({
        path: "/barging/index",
        query: {
          id: row.bid,
          pageStatus: 1,
        },
      });
    },
    getRequestNum() {
      getRequestNum().then((res) => {
        this.tabsList = [
          {
            label: "PendingApproval",
            count: res.Approval,
            index: "0",
          },
          {
            label: "Acknowledge",
            count: res.Acknowledge,
            index: "1",
          },
        ];
      });
    },
  },
};
</script>

<style scoped lang="scss">
.top {
  // margin-top: 45px;

  .tabs {
    // margin-left: 50px;
  }
}

.Topinp {
  position: relative;
}

.Topinput {
  display: flex;
  position: absolute;
  top: -35px;
  right: 70px;

  .Comipt {
    width: 275px;
    height: 46px;
  }

  .Pendingselect {
    width: 221px;
    height: 46px;
    margin-left: 16px;
  }
}

.Reqcol {
  display: flex;
  justify-content: center;
  // height: calc(90vh - 80px - 32 * 2px - 56px);
  overflow-y: scroll;
  .ReqTable {
    height: 100%;
  }

  .el-button--default {
    color: #fff;
    background-color: #008CDB;
  }

  .el-button--default.is-plain:hover {
    color: #fff;
    background: #008CDB;
    border-color: #008CDB;
  }

  .el-button--default:focus {
    color: #fff !important;
    background: #008CDB !important;
    border-color: #008CDB;
  }
}

::v-deep {
  .el-input__icon.el-icon-search {
    height: 32px !important;
  }
}

::v-deep.el-table th.el-table__cell>.cell {
  font-size: 12px;
  color: #82889c !important;
  // padding-left: 25px;
}

::v-deep .el-table__row>.el-table__cell>.cell {
  font-size: 11px;
  color: #2e3139;
}

::v-deep .pagination-container {
  background: #f2f7fa;
  padding-right: 0;
  padding: 20px 0 0 0;
}

::v-deep .el-input__suffix-inner {
  padding-right: 5px;
  height: 32px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  svg {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
</style>
